import { Null } from '../types';
import { tryParse } from './json';

export enum StorageType {
  Local,
  Session
}

export class DellStorage implements Storage {
  constructor(protected storageType: StorageType = StorageType.Session, protected keyPrefix: string = 'dcjs') {
    this._storage = storageType !== StorageType.Local ? window.sessionStorage : window.localStorage;
  }

  private _storage: Storage;

  protected get storage(): Storage {
    return this._storage;
  }

  protected formatKey(key: string): string {
    if (this.keyPrefix)
      return `${this.keyPrefix}:${key}`;
    return key;
  }

  getItem<T>(key: string): T {
    return tryParse(this.storage.getItem(this.formatKey(key))).result as T;
  }

  setItem<T>(key: string, value: T) {
    if (typeof value !== undefined)
      this.storage.setItem(this.formatKey(key), JSON.stringify(value));
  }

  clear(): void {
    if (!this.keyPrefix) {
      this.storage.clear();
      return;
    }
    for (let i = 0; i < this.storage.length; i++) {
      const key = this.storage.key(i);
      if (key?.startsWith(this.keyPrefix + ':')) {
        this.storage.removeItem(key);
      }
    }
  }

  removeItem(key: string) {
    this.storage.removeItem(this.formatKey(key));
  }

  exists(key: string): boolean {
    return this.storage.getItem(this.formatKey(key)) !== null;
  }

  get length(): number {
    return this.storage.length;
  }

  key<T>(index: number): Null<string> {
    return this.storage.key(index);
  }

  createNew(keyPrefix: string): DellStorage {
    return new DellStorage(this.storageType, keyPrefix);
  }
}

export const localStorage: DellStorage = typeof dellCoreJs === 'undefined' ? new DellStorage(StorageType.Local) : dellCoreJs.dom.localStorage;

export const sessionStorage: DellStorage = typeof dellCoreJs === 'undefined' ? new DellStorage(StorageType.Session) : dellCoreJs.dom.sessionStorage;
