import { parseHtml } from "../../dom-functions";
import { DellHtmlElement } from "../dell-html-element";
import { DellMfeData } from "./dell-mfe-data";

export class DellMfe<TData extends DellMfeData = DellMfeData> extends DellHtmlElement<TData> {
  static elementName = 'dell-mfe';
  static styles: string[] = [`<!--Optional: You can define inline styles here. They will be added only once when the first instance of this MFE is created.-->`];
  static Name: string = 'Mfe';

  //The following methods are intended to only be implemented by the derived class.
  //The default implementation for each on DellHtmlElement are completely empty.
  //You do not need to call "super" methods for any of these.
  protected _adoptedCallback() {/*Invoked each time the custom element is moved to a new document.*/ };
  protected _attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {/*Invoked each time one of the custom element's data attributes are added, removed, or changed*/ };
  protected _connectedCallback() {/*Invoked each time the custom element is appended into a document connected element*/ };
  protected _disconnectedCallback() {/*Invoked each time the custom element is disconnected from the document's DOM.*/ };
  protected _init() {/*This serves as the static constructor for the MFE*/ };
  protected _render(fragment: DocumentFragment): void {
    //This is the render method you must implement.
    //The public render method is implemented by DellHtmlElement and is not intended to be overwritten.
    //Make any changes you wish to display/render to the fragment that is passed to you.
    //Making changes to the fragment prevents multiple 
    fragment.appendChild(parseHtml(`
      <h4>Dell MFE 1</h4>
      <p>
        This is a sample MFE with an element name of "${this.localName}". This MFE has ${this.observedAttributes.length} observable data attributes.
        You can pass data into this MFE by using one of the following attributes: ${this.observedAttributes.join(', ')}.
        Each of these observed data attributes are mapped into the data property. 
        The attribute names are converted into property names using the dataset/DOMStringMap convention.
      </p>
		  <div>
			  <pre><code>${JSON.stringify(this.data, null, "\t")}</code></<pre>
		  </div>
    `));
  };
  protected _validate(errors: string[]): void {
    //This is a validation example from DellHtmlElement
    if(errors.length)
      errors.push('Some data property is invlaid.');
  }
}
