import { DellPerformance } from './dell-performance';

export interface DellPerformanceMarkResult {
  end?: PerformanceMark,
  start?: PerformanceMark,
  measure?: PerformanceMeasure
}

export class DellPerformanceMark {
  private endName: string;
  private startName: string;

  constructor(public name: string, public performance: DellPerformance = performance) {
    this.endName = `${this.name}-end`;
    this.startName = `${this.name}-start`;
  }

  result: {
    end?: PerformanceMark,
    start?: PerformanceMark,
    measure?: PerformanceMeasure
  } = {};

  private measure(options?: PerformanceMeasureOptions): DellPerformanceMark {
    options = options || {} as PerformanceMeasureOptions;
    options.start = this.startName;
    options.end = this.endName;
    this.result.measure = this.performance.measure(this.name, options);
    return this;
  }

  end(markOptions?: PerformanceMarkOptions, measureOptions?: PerformanceMeasureOptions): DellPerformanceMark {
    this.result.end = this.performance.mark(this.endName, markOptions);
    return this.measure(measureOptions);
  }

  start(options?: PerformanceMarkOptions): DellPerformanceMark {
    this.result.start = this.performance.mark(this.startName, options);
    return this;
  }

  log(): void {
    this.performance.logMeasures(this.name);
  }
}
