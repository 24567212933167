import { Null, UNDEF, Undef } from '../types';
import { consoleMap } from './console/dell-console-map';
import { shadowRoots } from './shadow-root';

const console = consoleMap.default;

export function cloneScript(script: HTMLScriptElement) {
  var scriptClone = document.createElement('SCRIPT') as HTMLScriptElement;
  for (var i = 0; i < script.attributes.length; i++) {
    scriptClone.setAttribute(script.attributes[i].name, script.attributes[i].value);
  }
  scriptClone.async = false;
  scriptClone.innerText = script.innerText;
  return scriptClone;
}

export function forEachElement(element: Element | ShadowRoot, callback: (element: Element) => void, includeShadows: boolean = false): void {
  if (element instanceof Element) {
    callback(element);
    if (includeShadows && element.shadowRoot) {
      element = element.shadowRoot;
    }
  }
  element.querySelectorAll('*').forEach((e) => {
    callback(e);
    if (includeShadows && e.shadowRoot) {
      forEachElement(e.shadowRoot as ShadowRoot, callback, includeShadows);
    }
  });
}

export function getMetaContent(name: string) {
  return document.head.querySelector('meta[name="' + name + '"]')?.getAttribute('content') || '';
}

export function getQueryStringValue(name: string): Undef<string> {
  const query = window.location.search.substring(1);
  if (query) {
    var items = query.split('&');
    for (var i = 0; i < items.length; i++) {
      var kv = items[i].split('=');
      if (decodeURIComponent(kv[0].toLowerCase()) === name.toLowerCase()) {
        return decodeURIComponent(kv[1]);
      }
    }
  }
  return UNDEF;
}

export const isIE11 = !!(window as any).MSInputMethodContext && !!(document as any).documentMode;

export function isInShadow(node: Node) {
  while (node?.parentNode !== null) {
    node = node.parentNode;
    if (node instanceof ShadowRoot) {
      return true;
    }
  }
  return false;
}

export function loadHtml(selector: string | Element, html: string): boolean {
  console.groupCollapsed('loadHtml');
  console.debug('loadHtml', 'begin', 'selector:', typeof selector === 'string' ? selector : selector.localName, 'html:', html.length > 250 ? html.substring(0, 250) + '...' : html);
  if (typeof selector === 'string')
    selector = document.querySelector(selector) || selector;
  if (!(selector instanceof Element)) {
    console.groupEnd();
    throw new Error(`Unable to load html. Selector (${selector}) is not an instance of an Element.`);
  }
  const fragment = parseHtml(html);
  selector.appendChild(fragment);
  console.debug('loadHtml', 'end');
  console.groupEnd();
  return true;
}

export function parseHtml(html: string): DocumentFragment {
  const fragment = document.createRange().createContextualFragment(html);
  fragment.querySelectorAll('script').forEach(script => {
    if (script.src?.length > 0) {
      const fileName = script.src.replace(/^.*[\\\/]/, '');
      if (document.querySelector('script[src="' + script.src + '"]') ||
        document.querySelector('script[src*="' + fileName + '"]') ||
        (script.hasAttribute('dell-ie-only') && !isIE11)) {
        (script.parentElement || fragment).removeChild(script);
        return;
      }
    }
    script.parentNode!.replaceChild(cloneScript(script), script);
  });
  return fragment;
}

export function parseHtmlIntoDiv(html: string): HTMLDivElement {
  const div = document.createElement("DIV") as HTMLDivElement;
  div.appendChild(parseHtml(html));
  return div;
}

export function queryElements(element: Element, callback: (element: Element) => boolean, includeShadows: boolean = false): Element[] {
  let results = new Array<Element>();
  forEachElement(element, (e) => {
    if (callback(e))
      results.push(e);
  }, includeShadows);
  return results;
}

export function querySelector(selector: string): Null<Element> {
  let domResult = document.querySelector(selector);
  if (!!domResult)
    return domResult;
  for (let i = 0; i < shadowRoots.length; i++) {
    const shadowResult = shadowRoots[i]?.querySelector(selector);
    if (!!shadowResult) {
      return shadowResult;
    }
  }
  return null;
}

export function querySelectorAll(selector: string): Element[] {
  const results = [] as Element[];
  const domResult = document.querySelectorAll(selector);
  if (!!domResult) {
    domResult.forEach(el => {
      results.push(el);
    });
  }
  for (let i = 0; i < shadowRoots.length; i++) {
    const shadowResult = shadowRoots[i]?.querySelectorAll(selector);
    if (!!shadowResult) {
      shadowResult.forEach(el => {
        results.push(el);
      });
    }
  }
  return results;
}

export function tryLoadHtml(selector: string | Element, html: string): boolean {
  try {
    return loadHtml(selector, html);
  }
  catch (e) {
    console.error('Error loading html.', e, selector, html);
    return false;
  }
}