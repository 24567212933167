import { DellMapString } from '../dell-map';
import { DellError } from '../dom/dell-error';
import { NullUndefString } from '../types';
import { Methods, RequestHandler } from './types';

export class Request {
  public request = new XMLHttpRequest();
  protected onSuccessHandler: RequestHandler = undefined;
  protected onErrorHandler: RequestHandler = undefined;
  protected onCompleteHandler: RequestHandler = undefined;
  protected body: NullUndefString = undefined;

  constructor(public method: Methods, public url: string, public withCredentials = true) {
    this.request.withCredentials = this.withCredentials;
    this.request.open(this.method, this.url);
  }

  public setBody(body: NullUndefString): Request {
    this.body = body;
    return this;
  }

  public setRequestHeaders(headers?: DellMapString<string>): Request {
    headers?.forEach((value, key) => {
      this.request.setRequestHeader(key, value);
    });
    return this;
  }

  public onSuccess(handler: RequestHandler): Request {
    this.onSuccessHandler = handler;
    return this;
  }

  public onError(handler: RequestHandler): Request {
    this.onErrorHandler = handler;
    return this;
  }

  public onComplete(handler: RequestHandler): Request {
    this.onCompleteHandler = handler;
    return this;
  }

  public send(): void {
    if (this.onCompleteHandler) {
      this.request.onerror = this.request.onload = () => {
        if (this.onCompleteHandler)
          this.onCompleteHandler(this.request);
        else {
          throw new DellError(`OnCompleteHandler not set for request.`, {
            status: this.request.status,
            responseURL: this.request.responseURL,
            responseText: this.request.responseText
          })
        }
      }
    }
    else {
      this.request.onload = () => {
        if (this.request.status === 200 && this.onSuccessHandler)
          this.onSuccessHandler(this.request);
        else if (this.onErrorHandler)
          this.onErrorHandler(this.request);
        else
          console.error(this.request.responseText);
      }
      this.request.onerror = () => {
        if (this.onErrorHandler)
          this.onErrorHandler(this.request);
        else
          console.error(this.request.responseText);
      }
    }
    this.request.send(this.body);
  }
}