import { consoleMap } from "../dom/console/dell-console-map";

const console = consoleMap.default;

export interface DellEvent {
  name: string;
  data: any;
  element?: Element;
}

const listeners: Array<(event: DellEvent) => void> = [];

export function listen(callback: (event: DellEvent) => void): void {
  listeners.push(callback);
}

export function emit(name: string, data?: any, element?: Element): void {
  console.debug('events.emit', 'name:', name, 'data:', data, 'element:', element);
  const event = { name, data, element } as DellEvent;
  listeners.forEach((listener) => {
    try {
      listener(event);
    }
    catch (e) {
      console.error(e);
    }
  });
}

export function emitError(error: Error | string, name: string = 'error', element?: Element): void {
  if (typeof error === 'string')
    error = new Error(error);
  console.error(error);
  emit(name, error, element);
}
