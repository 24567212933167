import { DellMapString, DellMapStringObjectDefault } from '../../dell-map';
import { UNDEF } from '../../types';
import { DellPerformance } from './dell-performance';
import { DefaultPriorityLevel, PriorityLevels, priorityLevelSwitches } from './priotity-level-switch';

export interface DellPerformanceMap extends DellMapString<DellPerformance> {
  setGroupsNeverCollapsed(value: boolean): void;
}

export class DellPerformanceMap extends DellMapStringObjectDefault<DellPerformance> implements DellPerformanceMap {
  ssdset(key: string, value?: PriorityLevels): DellPerformance {
    if (!this.has(key)) {
      this.set(key, new DellPerformance(key, typeof value === 'number' ? value : DefaultPriorityLevel));
      return this.get(key)!;
    }
    const result = this.get(key)!;
    if (typeof (value) === 'number')
      result.priorityLevel.value = value;
    return result;
  }

  setGet(key: string, value?: PriorityLevels): DellPerformance {
    const valueIsNumber = typeof value === 'number';
    if (!valueIsNumber)
      value = UNDEF;
    let result: DellPerformance;
    if (!this.has(key)) {
      result = new DellPerformance(key, value);
      this.set(key, result);
    } else {
      result = this.get(key)!;
    }
    if (valueIsNumber) {
      result.priorityLevel = priorityLevelSwitches.setGet(result.priorityLevel.key, value);
    }
    return result;
  }

  toConsoleByMeasure() {
    this.toConsoleByType('measure')
  }

  toConsoleByMark() {
    this.toConsoleByType('mark')
  }

  toConsoleByType(type: string) {
    console.group(`Performance Entires By Type(${type})`);
    const pEntries = window.performance.getEntriesByType(type);
    const entriesArray: any[] = [];
    pEntries.forEach(pe => {
      if (!pe.name.startsWith('dcjs-'))
        return;
      entriesArray.push(pe);
    });
    console.table(entriesArray);
    console.groupEnd();
  }

  toConsole() {
    console.group('Performance Entries');
    const perfMeasures = window.performance.getEntries();
    const measures: any[] = [];
    perfMeasures.forEach(p => {
      if (!p.name.startsWith('dcjs-'))
        return;
      measures.push(p);
    });
    console.table(measures);
    console.groupEnd();
  }
}

const performanceMap: DellPerformanceMap = typeof dellCoreJs === 'undefined' ? new DellPerformanceMap() : dellCoreJs.dom.performance.performanceMap;
performanceMap.set('', new DellPerformance());

export { performanceMap as map, performanceMap };
