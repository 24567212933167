import { UNDEF, Undef } from '../types';
import { DellPageContext } from './page-context';
import { Context, Locale, segmentDisplayNames, segments } from './types';

let pageContext: Context;
export function getPageContext(): Context {
  if (pageContext !== undefined)
    return pageContext;
  const dellPageContext = new DellPageContext();
  pageContext = {
    country: dellPageContext.country,
    customerSet: dellPageContext.customerSet,
    language: dellPageContext.language,
    segment: dellPageContext.segment,
    segmentDisplayName: dellPageContext.segmentDisplayName
  };
  return pageContext;
}

export function getSegment(segmentDisplayName: string): string {
  return segmentDisplayNames[segmentDisplayName?.toLowerCase()]?.segment!;
}

export function getSegmentDisplayName(segment: string): string {
  return segments[segment?.toLowerCase()]?.displayName!;
}

export function getUrl(mfeData?: any): string {
  if (mfeData?.contextUrl)
    return mfeData?.contextUrl;
  const userContext = getPageContext();  
  if (userContext.language && userContext.country) {
    let url = '/' + userContext.language + '-' + userContext.country;
    if (userContext.segmentDisplayName) {
      url += '/' + userContext.segmentDisplayName;
      if (userContext.customerSet)
        url += '/' + userContext.customerSet;
    }
    return url;
  }
  return '';
}

export function isValidSegment(segment: string): boolean {
  return !!segments[segment?.toLowerCase()];
}

export function isValidSegmentDisplayName(segmentDisplayName: string): boolean {
  return !!segmentDisplayNames[segmentDisplayName?.toLowerCase()];
}

export function parseLocale(value: string): Undef<Locale> {
  if (!value || value.length != 5 || value[2] != '-')
    return UNDEF;
  return {
    language: value.substring(0, 2),
    country: value.substring(3)
  };
}
