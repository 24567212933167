export interface Context {
  country: string;
  language: string;
  segment: string;
  segmentDisplayName: string;
  customerSet: string;
}

export interface Locale {
  country: string;
  language: string;
}

export interface Segment {
  displayName: string;
  value: string;
}

export interface SegmentDisplayName {
  segment: string;
  value: string;
}

export const segmentDisplayNames: Record<string, SegmentDisplayName> = {
  home: {
    segment: 'dhs',
    value: 'home'
  },
  work: {
    segment: 'bsd',
    value: 'work'
  },
  premier: {
    segment: 'bsd',
    value: 'premier'
  },
  member: {
    segment: 'eep',
    value: 'member'
  },
  '': {
    segment: 'gen',
    value: ''
  }
};

export const segments: Record<string, Segment> = {
  dhs: {
    displayName: 'home',
    value: 'dhs'
  },
  bsd: {
    displayName: 'work',
    value: 'bsd' },
  eep: {
    displayName: 'member',
    value: 'eep' },
  gen: {
    displayName: '',
    value: 'gen' }
};
