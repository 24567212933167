import { NullUndefString, TryResult, Undef } from "../types";

export function stringifyParse<T = any>(obj: any): T {
  return JSON.parse(JSON.stringify(obj || {})) as T;
}

export function tryParse(
  value: NullUndefString,
  defaultValue?: any,
  reviver?: (this: any, key: string, value: any
  ) => any): TryResult {
  value = value === "undefined" ? undefined : value;
  let result = defaultValue === undefined ? value : defaultValue;
  let error: Undef<Error> = undefined;
  try {
    if (value === undefined)
      throw new TypeError("Value cannot be undefined.");
    result = JSON.parse(value as any, reviver);
  }
  catch (e) {
    error = e as Error;
  }
  return {
    error,
    hasError: error !== undefined,
    result
  };
}
