import { loader } from './customElements';
import { forEachElement } from './dom-functions';
import { shadowRoots } from './shadow-root';

const attachShadowOriginal = Element.prototype.attachShadow;
export function domInitialize() {
  loader.observer.observe(document.body, { attributes: false, characterData: false, childList: true, subtree: true });
  forEachElement(document.body, (element: Element) => {
    loader.load(element);
    if (element.shadowRoot)
      shadowRoots.push(element.shadowRoot);
  }, true);

  if (typeof attachShadowOriginal === 'function') {
    Element.prototype.attachShadow = function (init: ShadowRootInit): ShadowRoot {
      const shadowRoot = attachShadowOriginal.call(this, init);
      shadowRoots.push(shadowRoot);
      loader.observe(shadowRoot);
      return shadowRoot;
    }
  }
}
