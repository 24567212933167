
//Base Map for all other Dell Map objects
export interface DellMap<K, V> extends Map<K, V> {
  toConsole(): void;
}

export abstract class DellMap<K, V> extends Map<K, V> {
  toConsole() {
    window.console.group(this.constructor.name);
    this.forEach((value, key) => {
      window.console.groupCollapsed(`${key}`, value);
      window.console.groupEnd();
    });
    window.console.groupEnd();
  }
}

//<any, V>
export interface DellMapAny<V> extends DellMap<any, V> { }
export class DellMapAny<V> extends DellMap<any, V> implements DellMapAny<V> { }

//<any, any>
export interface DellMapAnyAny extends DellMapAny<any> { }
export class DellMapAnyAny extends DellMapAny<any> implements DellMapAnyAny { }

//<string, V>
export interface DellMapString<V> extends DellMap<string, V> { }
export class DellMapString<V> extends DellMap<string, V> implements DellMapString<V> {
  get(key: string = ''): V | undefined {
    return super.get(key);
  }
}

//<string, any>
export interface DellMapStringAny extends DellMapString<any> { }
export class DellMapStringAny extends DellMapString<any> implements DellMapStringAny { }

//<string, string>
export interface DellMapStringString extends DellMapString<string> { }
export class DellMapStringString extends DellMapString<string> implements DellMapStringString { }


export interface DellMapStringWithDefault<V> extends DellMap<string, V> {
  new(): DellMapString<V>;
  new <V>(entries?: readonly (readonly [string, V])[] | null): DellMapString<V>;
  new(keyPrefix: string): DellMapString<V>;
}

export class DellMapStringObjectDefault<V> extends DellMapString<V> implements DellMapString<V> {

  defaultKey: string = '';

  get default(): V {
    return this.get(this.defaultKey)!;
  };
}