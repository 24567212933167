import { Guid, NullUndefString } from './types';

export namespace guid {
  const isGuidRegex = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;

  export function isValidString(value: NullUndefString) {
    return isGuidRegex.test(value || "");
  }

  export function create(value: NullUndefString = undefined): Guid {
    if (isValidString(value))
      value as Guid;
    const windowAny = window as any;
    var crypto = windowAny.crypto || windowAny.msCrypto;
    if (crypto?.randomUUID)
      return crypto.randomUUID() as Guid;
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: any) => {
      return (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    }) as Guid;
  }

  export const empty = '00000000-0000-0000-0000-000000000000' as Guid;
}