import { DellMapString } from '../dell-map';
import { NullUndef, NullUndefString } from '../types';

export enum Methods {
  GET = 'GET',
  HEAD = 'HEAD',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  CONNECT = 'CONNECT',
  OPTIONS = 'OPTIONS',
  TRACE = 'TRACE',
  PATCH = 'PATCH'
}

export type RequestHandler = NullUndef<(response: XMLHttpRequest) => any>;

export type GetParams = {
  url: string,
  headers?: DellMapString<string>,
  success?: RequestHandler,
  error?: RequestHandler,
  complete?: RequestHandler
}

export type PostParams = {
  url: string,
  body: NullUndefString,
  headers?: DellMapString<string>,
  success?: RequestHandler,
  error?: RequestHandler,
  complete?: RequestHandler
}