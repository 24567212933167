
export interface KeyValuePair<K, V> {
  key: K;
  value: V;
}

export class KeyValuePair<K, V> implements KeyValuePair<K, V> {
  constructor(key: K, value: V) {
    this.key = key;
    this.value = value;
  }
  key: K;
  value: V;
}


export interface KeyValuePairString<V> { }
export class KeyValuePairString<V> extends KeyValuePair<string, V> implements KeyValuePairString<V> {
  constructor(key: string, value: V) {
    super(key, value);
  }
}

export interface KeyValuePairStringNumber { }
export class KeyValuePairStringNumber extends KeyValuePairString<number> implements KeyValuePairString<number>
{
  constructor(key: string, value: number) {
    super(key, value);
  }

}
