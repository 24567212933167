import { concatStrings, parseNumber } from './core-functions';
import { DellMapStringObjectDefault } from './dell-map';
import { KeyValuePairStringNumber } from './key-value-pair';

export function formatSwitchKey(prefix: string, name: string): string {
  return (name.startsWith(prefix) ? name : concatStrings(prefix, name, '-'));
}

export abstract class Switch extends KeyValuePairStringNumber implements Switch {

  static qsParams = new URLSearchParams(window.location.search);

  constructor(keyPrefix: string, key: string, value: number) {
    super(key, value);
    this.keyPrefix = keyPrefix;
    this.key = formatSwitchKey(keyPrefix, key)
    Switch.qsParams.forEach((value, key) => {
      if (this.key === key.toLowerCase()) {
        this.value = parseNumber(value);
      }
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  //you cannot initialize _value and _flagValue here. They are assigned during 
  //construction and assigning initialization values here overwrites the intended value
  private _value!: number;
  protected _flagsValue!: number;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  get flagsValue(): number {
    return this._flagsValue;
  }

  keyPrefix: string;

  get value(): number {
    return this._value;
  }

  set value(value) {
    this._setFlagsValue(value);
    this._value = value;
  }

  protected abstract _setFlagsValue(value: number): void;

  hasValue(): boolean {
    return !!this.value;
  }

  isEnabled(value: number): boolean {
    if (this.value == 0 && value == 0)
      return true;
    else if (this._flagsValue > 0 && value > 0)
      return (this._flagsValue & value) === value;
    return false;
  }
}

export class SwitchMap<S extends Switch> extends DellMapStringObjectDefault<S> { }
