import { DellMapString, DellMapStringObjectDefault } from "../../dell-map";
import { UNDEF } from "../../types";
import { DellConsole } from "./dell-console";
import { LogLevels, logLevelSwitches } from "./log-level-switch";

export interface DellConsoleMap extends DellMapString<DellConsole> {
  setGroupsNeverCollapsed(value: boolean): void;
}

export class DellConsoleMap extends DellMapStringObjectDefault<DellConsole> implements DellConsoleMap {
  private groupsNeverCollapsed: boolean = false;
  setGroupsNeverCollapsed(value: boolean): void {
    this.groupsNeverCollapsed = value;
    this.forEach(c => {
      c.groupsNeverCollapsed = this.groupsNeverCollapsed;
    });
  }
  setGet(key: string, value?: LogLevels): DellConsole {
    const valueIsNumber = typeof value === 'number';
    if (!valueIsNumber)
      value = UNDEF;
    let result: DellConsole;
    if (!this.has(key)) {
      result = new DellConsole(key, value);
      result.groupsNeverCollapsed = this.groupsNeverCollapsed;
      this.set(key, result);
    } else {
      result = this.get(key)!;
    }
    if (valueIsNumber) {
      result.logLevel = logLevelSwitches.setGet(result.logLevel.key, value);
    }
    return result;
  }
}

const consoleMap: DellConsoleMap = typeof dellCoreJs === 'undefined' ? new DellConsoleMap() : dellCoreJs?.dom.console.consoleMap;
consoleMap.set('', new DellConsole());

export {
  consoleMap,
  consoleMap as map
};
