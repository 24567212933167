import { UNDEF } from "../../../types";
import { DellHtmlElementData } from "../dell-html-element-data";

export interface DellMfeData extends DellHtmlElementData {
  mfeStringProp: string;
  mfeNumberProp: number;
  mfeBooleanProp: boolean;
  mfeOptionalString?: string
}

export class DellMfeData extends DellHtmlElementData implements DellHtmlElementData {
  mfeStringProp: string = UNDEF;
  mfeNumberProp: number = UNDEF;
  mfeBooleanProp: boolean = UNDEF;
  mfeOptionalString?: string = UNDEF;
}
