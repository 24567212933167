const TIME_LOAD_START = performance.now();
if (!performance.getEntriesByName('dcjs-load-start', 'mark').length)
  performance.mark('dcjs-load-start');
import './lib/polyfills';
import * as lib from './lib';
import { domInitialize } from './lib/dom/initialize';

type DellCoreJs = typeof lib;
declare global {
  interface Window {
    dellCoreJs: DellCoreJs;
    DellCustomElementRegistry: { [key: string]: string };
    DellCustomElementRegistryStatus: { [key: string]: lib.dom.customElements.DellCustomElementLoaderStatus };
    DellCustomElementRegistryIgnore: string[]
  }
  const dellCoreJs: DellCoreJs;
  var Dell: any;
}

function initilize() {
  lib.dom.performance.map.default.measure('initialize-dom', domInitialize);
  //We will be here once everything has completely loaded but the dellcoreJs variable is not yet available to the page
  const TIME_LOAD_END = performance.now();
  let TIME_LOAD_COMPLETE: number;
  //we will keep checking until dellCoreJs is available
  if (typeof (dellCoreJs) === 'undefined')
    wait();
  else
    done(performance.now());

  function done(time_load_complete: number) {
    TIME_LOAD_COMPLETE = time_load_complete;
    performance.mark('dcjs-load-end');
    performance.measure('dcjs-load', 'dcjs-load-start', 'dcjs-load-end');

    var onloadEvent = document.createEvent('Event');
    onloadEvent.initEvent('dellCoreJs_onLoad', true, false);
    dellCoreJs.dom.console.map.default.log('dellCoreJs_onLoad:', 'TIME_LOAD_START:', TIME_LOAD_START, 'TIME_LOAD_END:', TIME_LOAD_END, 'TIME_LOAD_COMPLETE:', TIME_LOAD_COMPLETE, 'TotalTime:', TIME_LOAD_COMPLETE - TIME_LOAD_START);
    document.dispatchEvent(onloadEvent);
  }

  function wait() {
    const checkForDellCorejs_interval_id = setInterval(function () {
      if (typeof (dellCoreJs) !== 'undefined') {
        clearInterval(checkForDellCorejs_interval_id);
        done(performance.now());
      }
    }, 0);
  }
}

if (typeof dellCoreJs === 'undefined') {
  lib.dom.performance.map.default.measure('initialize', initilize);
}

export * from './lib';
export * as mfe from './lib/dom/customElements/mfe';
