export function concatStrings(value1: any, value2: any, delimiter?: any): string {
  const string1 = toString(value1);
  const string2 = toString(value2);
  return string1 + (string1.length > 0 && string2.length > 0 ? toString(delimiter) : '') + string2;
}

export function forEachProto<T = any>(startObject: T, callback: (obj: T, index:  number) => void, stopObject?: any) {
  let _object = startObject;  
  let index = 0;
  while (true) {
    callback(_object, index++);
    if (_object === stopObject || _object === Object.prototype)
      break;
    _object = (_object as any).__proto__;
  }
}

export function ifNullOrUndefOrEmpty<TTrue, TFalse>(value: any, ifTrue: TTrue, ifFalse: TFalse) {
  if (isNullOrUndef(value) || value === '')
    return typeof ifTrue === 'function' ? ifTrue() : ifTrue;
  return typeof ifFalse === 'function' ? ifFalse() : ifFalse;
}

export function ifNullOrUndef<TTrue, TFalse>(value: any, ifTrue: TTrue, ifFalse: TFalse) {
  if (isNullOrUndef(value))
    return typeof ifTrue === 'function' ? ifTrue() : ifTrue;
  return typeof ifFalse === 'function' ? ifFalse() : ifFalse;
}

export function isEmptyString(value: any, trim: boolean = true) {
  return typeof (value) === 'string' && !(trim ? value.trim() : value);
}

export function isNullOrUndef(value: any) {
  return value === null || value === undefined;
}

export function isEqual(a: any, b: any): boolean {
  if (a === b) return true;
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b;
  if (a === null || a === undefined || b === null || b === undefined) return false;
  if (a.prototype !== b.prototype) return false;
  let keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) return false;
  return keys.every(k => isEqual(a[k], b[k]));
};

export function isType<T>(arg: any): arg is T {
  if (arg && Object.keys(arg || {}).length > 0) {
    return true;
  }
  return false;
}

 export function parseNumber(value: any, defaultValue: number = 0): number {
  const parsedValue = parseInt(value);
   return isNaN(parsedValue) ? defaultValue : parsedValue;
}

export function runWhen(run: () => void, when: () => boolean = () => true, checkWhenInterval?: number, checkWhenTimeout?: number, onTimeout?: () => void) {
  if (checkWhenInterval === undefined || checkWhenInterval === null)
    checkWhenInterval = 0;
  if (checkWhenTimeout === undefined || checkWhenTimeout === null)
    checkWhenTimeout = 5000;
  let runWhenIntervalId: any;
  let timeoutIntervalId: any;
  const clearIntervals = () => {
    clearTimeout(runWhenIntervalId);
    clearTimeout(timeoutIntervalId);
  };
  runWhenIntervalId = setInterval(() => {
    if (when()) {
      clearIntervals();
      run();
    }
  }, checkWhenInterval);
  const runWhenStartTime = Date.now();
  timeoutIntervalId = setInterval(() => {
    if ((Date.now() - runWhenStartTime) > checkWhenTimeout!)
      clearIntervals();
    if (onTimeout)
      onTimeout();
  }, 0);
}

export function toString(value: any): string {
  const valueType = typeof (value);
  return valueType === 'string' && value.length > 0 ? value : valueType === 'function' || valueType === 'object' ? value.ToString() : '';
}