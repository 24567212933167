import { InspectOptions } from "util";
import { logLevelSwitches, LogLevels, LogLevelSwitch } from "./log-level-switch";

export interface DellConsole extends Console {
  name: string;
  groupsNeverCollapsed: boolean;
  logLevel: LogLevelSwitch;
}

export class DellConsole implements DellConsole {

  constructor(name: string = '', logLevel?: LogLevels) {

    this.logLevel = logLevelSwitches.setGet(name, logLevel);

    this.name = name;
  }

  private _labelDelimiter: string = ':';

  private _formatLabel(label: any): any {
    if (Array.isArray(label))
      return [this._formatLabel(label[0] || ''), ...label.slice(1)];
    if (typeof label !== 'string')
      return label;
    label = this.name ? this.name + (label ? this._labelDelimiter + label : label) : label;
    if (label === '')
      return label;
    return `[${label}]`;
  }

  groupsNeverCollapsed: boolean = false;
  logLevel: LogLevelSwitch;
  name: string;

  assert(condition?: boolean, ...data: any[]): void;
  assert(value: any, message?: string, ...optionalParams: any[]): void;
  assert(p0?: any, p1?: any, ...p2: any[]): void {
    if (this.logLevel.hasValue()) {
      window.console.assert(p0, this._formatLabel(p1), ...p2);
    }
  }

  clear(): void {
    if (this.logLevel.hasValue())
      window.console.clear();
  }

  count(label?: string): void {
    if (this.logLevel.hasValue())
      window.console.count(this._formatLabel(label));
  }

  countReset(label?: string): void {
    if (this.logLevel.hasValue())
      window.console.countReset(this._formatLabel(label));
  }

  debug(...data: any[]): void;
  debug(message?: any, ...optionalParams: any[]): void;
  debug(p0?: any, ...p1: any[]): void {
    if (this.logLevel.isEnabled(LogLevels.Debug))
      window.console.debug(this._formatLabel(p0), ...p1);
  }

  dir(item?: any, options?: any): void;
  dir(obj: any, options?: InspectOptions): void;
  dir(p0?: any, p1?: any): void {
    if (this.logLevel.hasValue())
      window.console.dir(p0, p1);
  }

  dirxml(...data: any[]): void {
    if (this.logLevel.hasValue())
      window.console.debug(...data);
  }

  error(...data: any[]): void;
  error(message?: any, ...optionalParams: any[]): void;
  error(p0?: any, ...p1: any[]): void {
    if (this.logLevel.isEnabled(LogLevels.Error))
      window.console.error(this._formatLabel(p0), ...p1);
  }

  group(...data: any[]): void;
  group(...label: any[]): void;
  group(...p0: any[]): void {
    if (this.logLevel.isEnabled(LogLevels.Debug)) {
      window.console.group(...this._formatLabel(p0));
    }
  }

  groupCollapsed(...data: any[]): void;
  groupCollapsed(...label: any[]): void;
  groupCollapsed(...p0: any[]): void {
    if (this.logLevel.isEnabled(LogLevels.Debug)) {
      if (this.groupsNeverCollapsed) {
        window.console.group(...this._formatLabel(p0));
      }
      else {
        window.console.groupCollapsed(...this._formatLabel(p0));
      }
    }
  }

  groupEnd(): void {
    if (this.logLevel.isEnabled(LogLevels.Debug))
      window.console.groupEnd();
  }

  info(...data: any[]): void;
  info(message?: any, ...optionalParams: any[]): void;
  info(p0?: any, ...p1: any[]): void {
    if (this.logLevel.isEnabled(LogLevels.Info))
      window.console.info(this._formatLabel(p0), ...p1);
  }

  log(...data: any[]): void;
  log(message?: any, ...optionalParams: any[]): void;
  log(p0?: any, ...p1: any[]): void {
    if (this.logLevel.isEnabled(LogLevels.Log))
      window.console.log(this._formatLabel(p0), ...p1);
  }

  table(tabularData?: any, properties?: string[]): void;
  table(tabularData: any, properties?: readonly string[]): void;
  table(p0?: any, p1?: any): void {
    if (this.logLevel.hasValue())
      window.console.table(p0, p1);
  }

  time(label?: any): void {
    if (this.logLevel.hasValue())
      window.console.time(this._formatLabel(label));
  }

  timeEnd(label?: any): void {
    if (this.logLevel.hasValue())
      window.console.timeEnd(this._formatLabel(label));
  }

  timeLog(label?: string, ...data: any[]): void {
    if (this.logLevel.hasValue())
      window.console.timeLog(this._formatLabel(label), ...data);
  }

  timeStamp(label?: string): void {
    if (this.logLevel.hasValue())
      window.console.timeStamp(this._formatLabel(label));
  }

  trace(...data: any[]): void;
  trace(message?: any, ...optionalParams: any[]): void;
  trace(p0: any, ...p1: any[]): void {
    if (this.logLevel.hasValue())
      window.console.trace(this._formatLabel(p0), p1);
  }

  warn(...data: any[]): void;
  warn(message?: any, ...optionalParams: any[]): void;
  warn(p0: any, ...p1: any[]): void {
    if (this.logLevel.isEnabled(LogLevels.Warn))
      window.console.warn(this._formatLabel(p0), ...p1);
  }

  profile(label?: string): void {
    if (this.logLevel.hasValue())
      window.console.profile(this._formatLabel(label));
  }

  profileEnd(label?: string): void {
    if (this.logLevel.hasValue())
      window.console.profileEnd(this._formatLabel(label));
  }
}
