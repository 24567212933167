import { DellError } from '../../dom/dell-error';
import { forEachElement } from '../../dom/dom-functions';
import { shadowRoots } from '../../dom/shadow-root';
import { Switch } from '../../switch';
import { Undef } from '../../types';
import { DellConsole } from '../console/dell-console';
import { consoleMap } from '../console/dell-console-map';
import { HtmlManifest } from '../html-manifest';

const console = consoleMap.setGet('DellCustomElementLoader');
const observerConsole: DellConsole = consoleMap.setGet('mutationObserver');
if (!Switch.qsParams.has('dcjs-log-mutationObserver'))
  observerConsole.logLevel.value = 0;

export enum DellCustomElementLoaderStatus {
  failed = 'failed',
  loaded = 'loaded',
  loading = 'loading',
  preloaded = 'preloaded'
}

export interface DellCustomElementLoader {
  getBaseUri(elementName: string, manifestUrl?: string): string;
  load(element: Element): void;
  observe(element: Element | ShadowRoot): void;
  observerCallback: MutationCallback;
}

export class DellCustomElementLoader implements DellCustomElementLoader {
  constructor() {
    this.registry = window.DellCustomElementRegistry || (window.DellCustomElementRegistry = {});
    this.registryStatus = window.DellCustomElementRegistryStatus || (window.DellCustomElementRegistryStatus = {});
    this.registryIgnore = window.DellCustomElementRegistryIgnore || (window.DellCustomElementRegistryIgnore = []);
    this.observer = new MutationObserver(this.observerCallback);
  }

  readonly registry: Record<string, Undef<string>>;
  readonly registryStatus: Record<string, DellCustomElementLoaderStatus>;
  readonly registryIgnore: string[];
  readonly observer: MutationObserver;

  getBaseUri = (elementName: string, manifestUrl?: string) => {
    const url = window.DellCustomElementRegistry[elementName];
    if (!url)
      throw Error(`The manifest url is not defined for ${elementName} in DellCustomElementRegistry.`);
    const manifestUrlIndex = url.indexOf(manifestUrl || '/api/');
    return manifestUrlIndex > 0 ? url.substring(0, manifestUrlIndex) : url;
  }

  load(element: Element): void {
    const elementName = element.localName;
    let manifestUrl = this.registry[elementName];
    let hasAttribute = false;
    if (!manifestUrl) {
      if (elementName.indexOf('dell-') === 0)
        console.warn(`load`, elementName, `Mfe not found in DellCustomElementRegistry.`);
      return;
    }
    else if (this.registryStatus[manifestUrl])
      return;
    else if (element.hasAttribute('dell-preloaded')) {
      this.registryStatus[manifestUrl] = DellCustomElementLoaderStatus.preloaded;
      return;
    }
    else if ((hasAttribute = element.hasAttribute('dell-ignore')) || this.registryIgnore.includes(elementName)) {
      console.warn(`load`, `Not loading ${elementName} because it ${hasAttribute ? 'has been marked with the ignore attribute' : 'is defined in the ignore list'}.`);
      return;
    }
    console.debug(`load`, `Loading ${elementName} from ${manifestUrl}.`);
    this.registryStatus[manifestUrl] = DellCustomElementLoaderStatus.loading;

    HtmlManifest.LoadUrl(manifestUrl,
      m => {
        m.load();
        this.registryStatus[manifestUrl!] = DellCustomElementLoaderStatus.loaded;
      },
      e => {
        this.registryStatus[manifestUrl!] = DellCustomElementLoaderStatus.failed;
        console.error(new DellError('Unable to load DellCustomElement.', e, { elementName, manifestUrl }));
      });
  }

  observe(element: Element | ShadowRoot) {
    this.observer.observe(element, { attributes: false, characterData: false, childList: true, subtree: true });
    forEachElement(element, e => this.load(e), true);
  }

  observerCallback = (mutations: MutationRecord[], observer: MutationObserver) => {
    mutations.forEach((mutation) => {
      for (let i = 0; i < mutation.removedNodes.length; i++) {
        if ((mutation.removedNodes[i] as Element)?.shadowRoot) {
          shadowRoots.clean();
          break;
        }
      }
      mutation.addedNodes.forEach(node => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          const element = node as Element;
          observerConsole.debug('AddElementToDom:', element.localName, element.outerHTML);
          forEachElement(element as Element, e => {
            loader.load(e);
          }, true);
        }
      });
    });
  }
}

export const loader: DellCustomElementLoader = typeof dellCoreJs === 'undefined' ? new DellCustomElementLoader() : dellCoreJs?.dom.customElements.loader;
