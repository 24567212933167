import { getUrl, getPageContext } from '../context-functions';
import { consoleMap } from '../../dom/console';
import {  } from '../../dom';
import { UrlServiceContextKeys } from './types';

const console = consoleMap.default;

export class UrlService {
  private emptySegment = '{EMPTY_SEGMENT}';
  private findAndReplaceDictionary: Record<string, string>;
  constructor() {
    const context = getPageContext();
    this.findAndReplaceDictionary = {
      [UrlServiceContextKeys.country]: context['country'],
      [UrlServiceContextKeys.customerSet]: context['customerSet'],
      [UrlServiceContextKeys.language]: context['language'],
      [UrlServiceContextKeys.languageCountry]: context['language'] + '-' + context['country'],
      [UrlServiceContextKeys.noContext]: '',
      [UrlServiceContextKeys.segmentDisplayName]: context['segmentDisplayName'],
      [UrlServiceContextKeys.segment]: context['segment']
    }
  }

  public combine(url1: string, url2: string) {
    if (!url1)
      return url2;
    if (!url2)
      return url1;
    if (url1.endsWith('/'))
      url1 = url1.slice(0, -1);
    if (url2.startsWith('/'))
      url2 = url2.slice(1);
    return url1 + '/' + url2;
  }

  public formatUrl(url: string): string {
    if (!url)
      return url;

    if (!this.urlContainsContextKey(url))
      return this.combine(url, getUrl());
    for (let key in this.findAndReplaceDictionary) {
      const replaceValue = (!!this.findAndReplaceDictionary[key]) ? this.findAndReplaceDictionary[key] : this.emptySegment;
      url = url.replace(key, replaceValue);
    }
    url = url.replace(this.emptySegment + '/', '').replace(this.emptySegment, '');
    console.debug('formatUrl: ' + url);
    return url;
  }

  public urlContainsContextKey(url: string): boolean {
    for (const key in this.findAndReplaceDictionary) {
      if (url.indexOf(key) > -1)
        return true;
    }
    return false;
  }
}

export const urlService = new UrlService();