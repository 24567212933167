
export interface DellError extends Error { }

function isErrorOptions(value: any) {
  return typeof value === 'object' && 'cause' in value && value.cause instanceof Error;
}

export interface DellErrorConstructor extends ErrorConstructor {

  new(message: string, data?: any): DellError;
  new(message: string, cause: Error, data?: any): DellError;
  new(message?: string, options?: ErrorOptions): Error;
  (message?: string, options?: ErrorOptions): Error;
}

export class DellError extends Error implements DellError {
  constructor(message: string, data?: any);
  constructor(message: string, cause: any, data?: any);
  constructor(message?: string, options?: ErrorOptions);
  constructor(p0?: string, p1?: any, p2?: any) {
    const p1_is_ErrorOptions = isErrorOptions(p1);
    const p1_is_error = !p1_is_ErrorOptions && p1 instanceof Error;
    if (p1_is_ErrorOptions)
      super(p0, p1);
    else
      super(p0);
    this.name = 'DellError';
    if (p1_is_error)
      this.cause = p1;
    let data: any = p2 !== undefined ? p2 :
      !p1_is_ErrorOptions && !p1_is_error ? p1 : undefined;
    if (data) {
      this.message += `\nData:${JSON.stringify(data, null, 2)}`;
    }
  }
}

export class DellValidationError extends DellError {
  constructor(message: string, data?: any);
  constructor(message: string, cause: any, data?: any);
  constructor(message?: string, options?: ErrorOptions);
  constructor(p0?: string, p1?: any, p2?: any) {
    if (isErrorOptions(p1))
      super(p0, p1);
    else
      super(p0);
    this.name = 'DellValidationError';
  }
}
