import { cookie } from '../dom';
import { getQueryStringValue } from '../dom/dom-functions';
import { UNDEF } from '../types';
import { parseLocale } from './context-functions';
import { Context, Locale, Segment, SegmentDisplayName, segmentDisplayNames, segments } from './types';

export class DellPageContext implements Context {
  constructor() {
    this.setValues();
  }
  protected setValuesFromCtxtCookie(): boolean {
    const dellCoreCtxtCookie = cookie.parse<{
      c: string;
      l: string;
      sdn: string;
    }>('dc-ctxt');
    if (dellCoreCtxtCookie) {
      this.country = dellCoreCtxtCookie.c;
      this.language = dellCoreCtxtCookie.l;
      this.segmentDisplayName = dellCoreCtxtCookie.sdn?.toLowerCase();
      this.segment = segmentDisplayNames[this.segmentDisplayName]?.segment;
      return true;
    }
    return false;
  }
  protected setValuesFromDellMetrics() {
    let dellMetricsSc = UNDEF;
    if (typeof Dell !== 'undefined' && (dellMetricsSc = Dell?.Metrics?.sc)) {
      this.country = dellMetricsSc.country;
      this.language = dellMetricsSc.language;
      this.customerSet = dellMetricsSc.customerset;
      this.segment = dellMetricsSc.segment;
      let segment;
      if (segment = segments[this.segment?.toLowerCase()]) {
        this.segment = segment.value;
        this.segmentDisplayName = segment.displayName;
      }
      if (!!dellMetricsSc.premier_roleid)
        this.segmentDisplayName = "premier";
      return true;
    }
    return false;
  }
  protected setValuesFromUrl(): boolean {
    let locale: Locale;
    let localeIndex: number = -1;
    let sdn: SegmentDisplayName;
    let segment: Segment;
    const urlSegments = window.location.pathname.split('/').splice(1);
    let urlSegmentIndex = urlSegments.length - 1;
    const setValuesFromLegacyPremierUrl = () => {
      if (urlSegments.length - 4 >= 0) {//must have at least 4 segments but could have more
        const firstUrlSeg = urlSegments[0].toLowerCase();
        if (firstUrlSeg === "premier" || firstUrlSeg === "account") //path must begin with account or premier)
        {
          var startIndex = -1;
          //find start index for pattern of "/xx/xx/RCxxxxxxx" in url
          for (var i = 1; i < urlSegments.length; i++) {
            if (urlSegments[i].length == 2 && urlSegments.length > (i + 1) &&
              urlSegments[i + 1].length == 2 && urlSegments.length > (i + 2) &&
              urlSegments[i + 2].length >= 9 && urlSegments[i + 2].toUpperCase().startsWith("RC")
            ) {
              startIndex = i;
              break;
            }
          }
          if (startIndex > -1) {
            this.country = urlSegments[startIndex];
            this.language = urlSegments[startIndex + 1];
            sdn = segmentDisplayNames['premier'];
            this.segmentDisplayName = sdn.value;
            this.segment = sdn.segment;
            this.customerSet = urlSegments[startIndex + 2];
            return true;
          }
        }
      }
      return false;
    }
    while (urlSegmentIndex >= 0) {
      if (urlSegments[urlSegmentIndex].length == 5 && urlSegments[urlSegmentIndex].indexOf("-") == 2) {
        localeIndex = urlSegmentIndex;
        locale = parseLocale(urlSegments[urlSegmentIndex])!;
        this.country = locale.country;
        this.language = locale.language;
        break;
      }
      urlSegmentIndex--;
    }
    if (localeIndex > -1) {
      //Has Locale
      //Unified                 //host/{appParams}/{locale}/{segmentDisplayName}/{customerSet}/
      //LegacyCsb               //host/{locale}/{segmentDisplayName}/{appParams}?cs={customerSet}/
      if (urlSegments.length > (localeIndex + 1) && (sdn = segmentDisplayNames[urlSegments[localeIndex + 1].toLowerCase()])) {
        this.segment = sdn.segment;
        this.segmentDisplayName = sdn.value;
        if (!(this.customerSet = getQueryStringValue('cs')!) && urlSegments.length > (localeIndex + 2))
          this.customerSet = urlSegments[localeIndex + 2];
      }
      //LegacyCsbApiWithLocale  //host/{appParams}/{locale}/{appParams}/{country}/{segment}/{customerSet}  (https://www.dell.com/csbapi/en-us/productsoldout/us/dhs/19)
      else if (urlSegments.length - 2 > 0 && urlSegments[0].toLowerCase() === "csbapi" && (segment = segments[urlSegments[urlSegments.length - 2].toLowerCase()])) {
        this.segment = segment.value;
        this.segmentDisplayName = segment.displayName;
        this.customerSet = urlSegments[urlSegments.length - 1];
      }
      return true;
    }
    //LegacyPremier             //host/{account|premier}/{appParams?}/{country}/{language}/{customerSet}/{appParams?}
    else if (setValuesFromLegacyPremierUrl()) {
      return true;
    }
    //LegacyCsbApi              //host/{appParams}/{country}/{language}/{segment}/{customerSet}/
    else if (urlSegments.length - 4 >= 0 && //must have at least 4 segments but could have more
      urlSegments[urlSegments.length - 4].length == 2 && //ensure country is 2 chars
      urlSegments[urlSegments.length - 3].length == 2 && //ensure language is 2 chars
      (segment = segments[urlSegments[urlSegments.length - 2].toLowerCase()])) //ensure segment is 3 chars
    {
      this.country = urlSegments[urlSegments.length - 4];
      this.language = urlSegments[urlSegments.length - 3];
      this.segmentDisplayName = segment.displayName;
      this.segment = segment.value;
      this.customerSet = urlSegments[urlSegments.length - 1];
      return true;
    }
    return false;
  }
  protected setValues(): void {
    if (!this.setValuesFromDellMetrics())
      if (!this.setValuesFromUrl())
        this.setValuesFromCtxtCookie();
  }
  public country: string = UNDEF;
  public language: string = UNDEF;
  public segment: string = UNDEF;
  public segmentDisplayName: string = UNDEF;
  public customerSet: string = UNDEF;
}
