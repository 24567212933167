export interface KeyValueConstructor<K, V, T> {
  new(key: K, value: V): T;
}

export type Guid = string & { isGuid: true };

export type HtmlManifestItem = {
  selector: string;
  html: string;
}

export type Null<T> = T | null;
export type NullString = Null<string>;
export type NullNumber = Null<number>;
export type NullBoolean = Null<boolean>;

export type NullUndef<T> = Null<T> | Undef<T>;
export type NullUndefString = NullString | UndefString;
export type NullUndefNumber = NullNumber | UndefNumber;
export type NullUndefBoolean = NullBoolean | UndefBoolean;

export type TryResult<TResult = any> = {
  readonly error: Undef<Error>,
  readonly hasError: boolean,
  readonly result: TResult;
}

export type Undef<T> = T | undefined;
export type UndefString = Undef<string>;
export type UndefNumber = Undef<number>;
export type UndefBoolean = Undef<boolean>;

export const UNDEF = undefined as any;
