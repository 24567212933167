import { DellMapString } from '../../dell-map';
import { DellHtmlElement, DellHtmlElementConstructor } from './dell-html-element';
import { DellHtmlElementData } from './dell-html-element-data';

export interface DellHtmlElementMap extends DellMapString<DellHtmlElement> {
  get(instanceId: string): DellHtmlElement | undefined;
  get(elementNames: string[]): DellHtmlElement[];
  get<T extends DellHtmlElement<TData>, TData extends DellHtmlElementData>(mfe: DellHtmlElementConstructor<T, TData>): DellHtmlElement[];
  get(): DellHtmlElement[];

  getByName(elementName: string): DellHtmlElement[];
}

export class DellHtmlElementMap extends DellMapString<DellHtmlElement> implements DellHtmlElementMap {
  get(instanceId: string): DellHtmlElement | undefined;
  get(elementNames: string[]): DellHtmlElement[];
  get<T extends DellHtmlElement<TData>, TData extends DellHtmlElementData>(mfe: DellHtmlElementConstructor<T, TData>): T[];
  get(): DellHtmlElement[];
  get(key?: any): DellHtmlElement | DellHtmlElement[] | undefined {
    if (typeof key === 'string') {
      //instanceId
      return super.get(key);
    }
    else if (Array.isArray(key)) {
      //array of elementNames
      return Array.from(this.values()).filter((mfe: DellHtmlElement, index) => {
        return key.indexOf((mfe as any).localName) > -1;
      });
    }
    else {
      if (key === undefined) {
        //return all mfes
        return Array.from(this, (mfeMapItem, index) => {
          window.console.log("mfeMapItem[1]", mfeMapItem[1]);
          return mfeMapItem[1];
        });
      }
      else {
        //Mfe constructor that has elementNames
        if (key.derivedElements)
          return this.get([key.elementName, ...key.derivedElements]);
        else
          return [];
      }
    }
  }
  getByName(elementName: string): DellHtmlElement[] {
    return this.get([elementName]);
  }

  render<T extends DellHtmlElement<TData>, TData extends DellHtmlElementData>(mfe: DellHtmlElementConstructor<T, TData>): void {
    this.get(mfe).forEach(m => m.render());
  }
}

const dellHtmlElementMap: DellHtmlElementMap = typeof dellCoreJs === 'undefined' ? new DellHtmlElementMap() : dellCoreJs?.dom.customElements.map;

export { dellHtmlElementMap as map, dellHtmlElementMap };
