import { Null, NullString, UNDEF, Undef } from '../types';
import { consoleMap } from './console/dell-console-map';

const console = consoleMap.default;

export function exists(name: string): boolean {
  return getRegex(encodeURIComponent(name)).test(document.cookie);
};

export function get(name: string): NullString {
  let value: NullString = null;
  if (exists(name)) {
    name = encodeURIComponent(name);
    const regExp = getRegex(name);
    const result = regExp.exec(document.cookie);
    if (result)
      value = decodeURIComponent(result[1]);
  }
  console.debug('Cookie.get:', 'name:', name, 'value:', value);
  return value;
}

export interface ParseCookieOptions {
  recordDelimiter: string;
  keyValueDelimiter: string;
}

export function parse<T extends { [key: string]: string; }>(name: string, options?: ParseCookieOptions): Undef<T> {
  options = Object.assign({ keyValueDelimiter: "=", recordDelimiter: "&" }, options);
  const regExp = getRegex(encodeURIComponent(name));
  const regexResults = regExp.exec(document.cookie);
  if (regexResults) {
    const returnValue = {} as any;
    regexResults[1].split(options.recordDelimiter).forEach(kv => {
      const keyValue = kv.split(options!.keyValueDelimiter);
      returnValue[keyValue[0]] = keyValue[1];
    });
    return returnValue as T;
  }
  return UNDEF;
}

export function getRegex(name: string): RegExp {
  let escapedName = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/ig, '\\$1');
  return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
};

export function set(
  name: string,
  value: string,
  expires: Date | Number = 30,
  encode: boolean = true,
  path: string = '/',
  domain: string = '.dell.com',
  secure: boolean = false,
  sameSite: Null<'lax' | 'strict'> = null
): void {
  let cookieString = (encode ? encodeURIComponent(name) : name) + '=' + (encode ? encodeURIComponent(value) : value) + ';';
  if (expires) {
    if (typeof expires === 'number') {
      const dateExpires = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);
      cookieString += 'expires=' + dateExpires.toUTCString() + ';';
    }
    else {
      cookieString += 'expires=' + (<Date>expires).toUTCString() + ';';
    }
  }
  if (path) {
    cookieString += 'path=' + path + ';';
  }
  if (domain) {
    cookieString += 'domain=' + domain + ';';
  }
  if (secure) {
    cookieString += 'secure;';
  }
  if (sameSite) {
    cookieString += 'sameSite=' + sameSite + ';';
  }
  console.debug('Cookie.set:', 'value:', cookieString, 'params:', {
    name: name,
    value: value,
    encode: encode,
    expires: expires,
    path: path,
    domain: domain,
    secure: secure,
    sameSite: sameSite
  });
  document.cookie = cookieString;
}
