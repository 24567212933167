import { UNDEF } from "../../types";

export interface DellHtmlElementData {
  locale?: string;
  metrics?: any;
  [key: string]: any;
}

export interface DellHtmlElementDataConstructor {
  new(): DellHtmlElementData;
}

export class DellHtmlElementData implements DellHtmlElementData {
  locale?: string = UNDEF;
  metrics?: any = {};
}
