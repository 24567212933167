import { Switch, SwitchMap } from "../../switch";
import { UNDEF } from "../../types";

export enum PriorityLevels {
  None = 0,
  Critical = 1 << 1,
  High = 1 << 2,
  Normal = 1 << 3,
  Low = 1 << 4,
  Debug = 1 << 5,
};

declare var dcjsDefaultPriorityLevel: number;
export const DefaultPriorityLevel = typeof dcjsDefaultPriorityLevel === 'number' ? dcjsDefaultPriorityLevel : PriorityLevels.Normal;

export class PriorityLevelSwitch extends Switch {
  constructor(key: string = '', value: number = DefaultPriorityLevel) {
    super('dcjs-perf', key, value);
  }

  _setFlagsValue(value: number) {
    switch (value) {
      case PriorityLevels.Debug:
        this._flagsValue = PriorityLevels.Debug | PriorityLevels.Low | PriorityLevels.Normal | PriorityLevels.High | PriorityLevels.Critical;
        break;
      case PriorityLevels.Low:
        this._flagsValue = PriorityLevels.Low | PriorityLevels.Normal | PriorityLevels.High | PriorityLevels.Critical;
        break;
      case PriorityLevels.Normal:
        this._flagsValue = PriorityLevels.Normal | PriorityLevels.High | PriorityLevels.Critical;
        break;
      case PriorityLevels.High:
        this._flagsValue = PriorityLevels.High | PriorityLevels.Critical;
        break;
      case PriorityLevels.Critical:
        this._flagsValue = PriorityLevels.Critical;
        break;
      default:
        this._flagsValue = PriorityLevels.None;
    }
  }
}

export class PriorityLevelSwitchMap extends SwitchMap<PriorityLevelSwitch>{
  setGet(key: string, value?: PriorityLevels): PriorityLevelSwitch {
    const valueIsNumber = typeof value === 'number';
    if (!valueIsNumber)
      value = UNDEF;
    let result: PriorityLevelSwitch;
    if (!this.has(key)) {
      result = new PriorityLevelSwitch(key, value || this.default?.value || DefaultPriorityLevel)
      this.set(key, result);
    }
    else {
      result = this.get(key)!;
    }
    if (valueIsNumber) {
      result.value = value!;
      //Sync other swithces that 'inherit' from result switch
      this.forEach((v, k) => {
        if (v.key !== result.key && v.value !== result.value && v.key.startsWith(result.key)) {
          v.value = value!;
        }
      });
    }
    return result;
  }
}

export const priorityLevelSwitches: PriorityLevelSwitchMap = typeof dellCoreJs === 'undefined' ? new PriorityLevelSwitchMap() : dellCoreJs.dom.performance.priorityLevelSwitches;
priorityLevelSwitches.set('', new PriorityLevelSwitch());
