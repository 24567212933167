import { getUrl } from '../context';
import { get } from '../http';
import { HtmlManifestItem, TryResult, UNDEF, Undef } from '../types';
import { consoleMap } from './console';
import { DellError } from './dell-error';
import { loadHtml } from './dom-functions';

const console = consoleMap.setGet('HtmlManifest');

export class HtmlManifest {
  items: HtmlManifestItem[] = [];
  constructor(items: Array<HtmlManifestItem>) {
    this.items = items;
  }

  load(): boolean {
    console.groupCollapsed('load');
    let result = false;
    try {
      for (let i = 0; i < this.items.length; i++)
        loadHtml(this.items[i].selector, this.items[i].html);
      result = true;
    }
    catch (e) {
      console.error('Error loading HtmlManifest.');
    }
    console.groupEnd();
    return result;
  }

  static Load(manifest: string): void {
    HtmlManifest.Parse(manifest).load();
  }

  static LoadUrl(url: string, callback?: (manifest: HtmlManifest) => void, errorCallback?: (error: Error) => void): void {
    console.debug(`LoadUrl`, 'begin', 'url:', url);
    get({
      url: `${url}${getUrl()}`,
      complete: (request) => {
        const failed = request.status !== 200;
        console.debug(`LoadUrl`, 'end', request.status, 'url:', url);
        let error: Error = UNDEF;
        if (!failed) {
          const parseResults = HtmlManifest.TryParse(request.responseText);
          if (!parseResults.error) {
            if (callback) {
              callback(parseResults.result);
            }
            else {
              parseResults.result.load();
            }
            return;
          }
          error = parseResults.error;
        }
        else {
          error = new DellError(`Unable to load HtmlManifest from ${url}: ${request.responseText}`)
        }
        if (errorCallback) {
          errorCallback(error);
        }
        else {
          console.error(error);
        }
      }
    });
  }

  static Parse(manifest: string): HtmlManifest {
    let manifestItems: Undef<Array<HtmlManifestItem>>;
    manifestItems = JSON.parse(manifest) as Array<HtmlManifestItem>;
    return new HtmlManifest(manifestItems || new Array<HtmlManifestItem>());
  }

  static TryParse(manifest: string): TryResult<HtmlManifest> {
    let result: HtmlManifest = UNDEF;
    let error: Error = UNDEF;
    try {
      result = HtmlManifest.Parse(manifest);
    }
    catch (e) {
      error = new DellError(`Error parsing HtmlManifest`, e, { manifest: manifest });
    }
    return {
      error,
      hasError: error !== undefined,
      result
    };
  }
}

