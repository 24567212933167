import { Switch, SwitchMap } from "../../switch";
import { UNDEF } from "../../types";

export enum LogLevels {
  None = 0,
  Error = 1 << 0,
  Warn = 1 << 1,
  Info = 1 << 2,
  Log = 1 << 3,
  Debug = 1 << 4
};

export const DefaultLogLevel = LogLevels.Info;

export class LogLevelSwitch extends Switch {
  constructor(key: string = '', value: number = DefaultLogLevel) {
    super('dcjs-log', key, value);
  }

  _setFlagsValue(value: number) {
    switch (value) {
      case LogLevels.Debug:
        this._flagsValue = LogLevels.Debug | LogLevels.Log | LogLevels.Info | LogLevels.Warn | LogLevels.Error;;
        break;
      case LogLevels.Log:
        this._flagsValue = LogLevels.Log | LogLevels.Info | LogLevels.Warn | LogLevels.Error;;
        break;
      case LogLevels.Info:
        this._flagsValue = LogLevels.Info | LogLevels.Warn | LogLevels.Error;
        break;
      case LogLevels.Warn:
        this._flagsValue = LogLevels.Warn | LogLevels.Error;
        break;
      case LogLevels.Error:
        this._flagsValue = LogLevels.Error;
        break;
      default:
        this._flagsValue = LogLevels.None;
    }
  }
}

export class LogLevelSwitchMap extends SwitchMap<LogLevelSwitch>{
  setGet(key: string, value?: LogLevels): LogLevelSwitch {
    const valueIsNumber = typeof value === 'number';
    if (!valueIsNumber)
      value = UNDEF;
    let result: LogLevelSwitch;
    if (!this.has(key)) {
      result = new LogLevelSwitch(key, value || this.default?.value || DefaultLogLevel)
      this.set(key, result);
    }
    else {
      result = this.get(key)!;
    }
    if (valueIsNumber) {
      result.value = value!;
      //Sync other swithces that 'inherit' from result switch
      this.forEach((v, k) => {
        if (v.key !== result.key && v.value !== result.value && v.key.startsWith(result.key)) {
          v.value = value!;
        }
      });
    }
    return result;
  }
}

export const logLevelSwitches: LogLevelSwitchMap = typeof dellCoreJs === 'undefined' ? new LogLevelSwitchMap() : dellCoreJs.dom.console.logLevelSwitches;
logLevelSwitches.set('', new LogLevelSwitch());