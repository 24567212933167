import { forEachProto } from "../../core-functions";
import { emit, emitError } from "../../events";
import { guid } from "../../guid";
import { Guid, Null } from "../../types";
import { DellConsole } from "../console/dell-console";
import { consoleMap } from "../console/dell-console-map";
import { DellValidationError } from "../dell-error";
import { DellStorage, localStorage, sessionStorage } from "../dell-storage";
import { isInShadow, parseHtml } from "./../dom-functions";
import { DellHtmlElementData, DellHtmlElementDataConstructor } from "./dell-html-element-data";
import { dataMapper } from "./dell-html-element-data-mapper";
import { dellHtmlElementMap, map as instances } from "./dell-html-element-map";

export interface DellHtmlElement<TData extends DellHtmlElementData = DellHtmlElementData> {
  //Static Properties
  readonly observedAttributes: string[];

  //Instance Properties
  readonly console: DellConsole;
  readonly data: TData;
  readonly hasData: boolean;
  readonly instanceId: Guid;
  readonly isDellMfe: boolean;
}

export interface DellHtmlElementConstructor<T extends DellHtmlElement<TData>, TData extends DellHtmlElementData> {
  new(): T;
  readonly dataClass: DellHtmlElementDataConstructor;
  readonly derivedElements: string[];
  readonly elementName: string;
  readonly extendsElement?: string
  readonly initialized: boolean;
  readonly observedAttributes: string[];
  readonly styles: string[];
  readonly stylesForShadow?: string;
  readonly stylesLoadedInHead: boolean;
}

export class DellHtmlElement<TData extends DellHtmlElementData = DellHtmlElementData> extends HTMLElement {
  //Static Members: Public Properties
  static dataClass: DellHtmlElementDataConstructor = DellHtmlElementData;
  static readonly derivedElements: string[] = [];
  static readonly elementName: string = 'dell-mfe';
  static readonly extendsElement?: string;
  static readonly initialized: boolean = false;
  static readonly observedAttributes: string[] = ['locale', 'metrics'];
  static readonly styles: string[] = [];
  static readonly stylesForShadow?: string;
  static readonly stylesLoadedInHead: boolean = false;

  constructor() {
    super();
    const instanceId = guid.create();
    const console = consoleMap.setGet(this.localName);
    const runInit = !this._ctor.initialized;
    const ctorConsoleLabel = `${instanceId}:constructor`;

    console.group(ctorConsoleLabel);
    console.debug(ctorConsoleLabel, 'begin');
    //initialize first instance
    if (!this._ctor.initialized) {
      console.debug('initializing constructor properties');
      forEachProto(this._ctor, (p, i) => {
        const props: PropertyDescriptorMap = {};
        if (!Object.hasOwn(p, 'derivedElements'))
          props.derivedElements = { configurable: false, writable: false, value: [] };
        if (!Object.hasOwn(p, 'initialized') || i === 0)
          props.initialized = { configurable: false, writable: i !== 0, value: i === 0 };
        if (!Object.hasOwn(p, 'styles'))
          props.styles = { configurable: false, writable: false, value: [] };
        if (!Object.hasOwn(p, 'stylesForShadow'))
          props.stylesForShadow = { configurable: false, writable: true, value: undefined };
        if (!Object.hasOwn(p, 'stylesLoadedInHead'))
          props.stylesLoadedInHead = { configurable: false, writable: true, value: false };
        Object.defineProperties(p, props);
        if (p.derivedElements.indexOf(this.localName) === -1) {
          p.derivedElements.push(this.localName);
        }
      }, DellHtmlElement);
    }
    //Set Instance Properties
    Object.defineProperties(this, {
      console: { configurable: false, value: console },
      data: { configurable: false, value: new this._ctor.dataClass() },
      hasData: { configurable: false, get: function () { return Object.keys(this.dataset).length > 0 } },
      instanceId: { configurable: false, value: instanceId },
      isDellMfe: { configurable: false, value: true },
      observedAttributes: { configurable: false, get: () => this._ctor.observedAttributes }
    });
    //Tell data mapper to start watching attributes
    dataMapper.observeAttributes(this);
    //Run the _init method
    if (runInit) {
      console.group('_init');
      this._init();
      console.groupEnd();
    }
    console.debug(ctorConsoleLabel, 'end');
    console.groupEnd();
  }

  //Instance Members: Private Properties
  private _isInShadow: boolean = false;
  private _localStorage!: DellStorage;
  private _renderInQueue: boolean = false;
  private _sessionStorage!: DellStorage;

  //Instance Members: Protected Properties                         
  protected get _ctor(): DellHtmlElementConstructor<DellHtmlElement<TData>, TData> {
    return this.constructor as DellHtmlElementConstructor<DellHtmlElement<TData>, TData>;
  }
  protected get _instances() {
    return dellHtmlElementMap.get(this._ctor);
  }

  //Instance Members: Public Properties
  readonly console!: DellConsole;
  readonly data!: TData;
  readonly hasData!: boolean;
  readonly instanceId!: Guid;
  readonly isDellMfe!: boolean;
  public get isInShadow(): boolean {
    return this._isInShadow;
  }
  public get localStorage() {
    if (this._localStorage === undefined)
      this._localStorage = localStorage.createNew(this.localName);
    return this._localStorage;
  }
  public get sessionStorage() {
    if (this._sessionStorage === undefined)
      this._sessionStorage = sessionStorage.createNew(this.localName);
    return this._sessionStorage;
  }

  //Instance Members: Protected Methods
  protected _adoptedCallback() { };
  protected _attributeChangedCallback(name: string, oldValue: Null<string>, newValue: Null<string>) { };
  protected _connectedCallback() { };
  protected _disconnectedCallback() { };
  protected _emitError(error: Error | string, name?: string) { };
  protected _emitEvent(name: string, data?: any) { };
  protected _init() { };
  protected _loadStyles(fragment?: DocumentFragment) {
    if (!this.isInShadow && this._ctor.stylesLoadedInHead)
      return;
    const stylesElement = document.createElement("STYLE");
    stylesElement.setAttribute("type", "text/css");
    const styles: string[] = [];
    if (this.isInShadow) {
      if (this._ctor.stylesForShadow === undefined) {
        forEachProto(this._ctor, (p) => {
          if (p.styles.length)
            styles.unshift(...p.styles);
        }, DellHtmlElement);
        Object.defineProperty(this._ctor, 'stylesForShadow', { configurable: false, writable: false, value: styles.join('\n') });
      }
      if (this._ctor.stylesForShadow) {
        stylesElement.textContent = this._ctor.stylesForShadow;
        fragment?.appendChild(stylesElement);
        this.console.debug(`${this.instanceId}:load styles in shadow`);
      }
    }
    else {
      forEachProto(this._ctor, (p) => {
        if (!p.stylesLoadedInHead) {
          Object.defineProperty(p, 'stylesLoadedInHead', { configurable: false, writable: false, value: true });
          if (p.styles.length)
            styles.unshift(...p.styles);
        }
      }, DellHtmlElement);
      if (styles.length) {
        stylesElement.setAttribute("dell-mfe", this.localName);
        stylesElement.textContent = styles.join('\n');
        document.head.appendChild(stylesElement);
        this.console.debug(`${this.instanceId}:load styles in head`);
      }
    }
  };
  protected _render(fragment: DocumentFragment): void {
    fragment.appendChild(parseHtml(`
			<p>This is the default protected DellHtmlElement._render method and it has not been overwritten by ${this.localName}.</p>
			<div>
				<code>${JSON.stringify(this.data, null, "\t")}</code>
			</div>
		`));
  };
  protected _setMetrics(element: Element, metrics: object): void {
    element.setAttribute('data-metrics', JSON.stringify(Object.assign({}, this.data.metrics, metrics)));
  };
  protected _validate(errors: string[]): void { }

  //Instance Members: PublicMethods
  adoptedCallback() {
    this.console.debug(`${this.instanceId}:adoptedCallback`);
    this._adoptedCallback();
  }
  attributeChangedCallback(name: string, oldValue: Null<string>, newValue: Null<string>): void {
    if (dataMapper.mapDataAttribute(this, name, oldValue, newValue)) {
      this.console.debug(`${this.instanceId}:attributeChangedCallback`, 'name:', name, 'oldValue:', oldValue, 'newValue:', newValue);
      this._attributeChangedCallback(name, oldValue, newValue);
      if (!this._renderInQueue) {
        this._renderInQueue = true;
        queueMicrotask(() => {
          this.console.groupCollapsed(`${this.instanceId}:validate/render`);
          this.validate();
          this.render();
          this.console.groupEnd();
        });
      }
    }
  }
  connectedCallback(): void {
    this._isInShadow = isInShadow(this);
    this.console.debug(`${this.instanceId}:connectedCallback`, 'isInShadow:', this.isInShadow);
    instances.set(this.instanceId, this);
    this._connectedCallback();
    this.render();
  }
  disconnectedCallback(): void {
    this.console.debug(`${this.instanceId}:disconnectedCallback`);
    instances.delete(this.instanceId);
    this._isInShadow = false;
    this._disconnectedCallback();
  }
  emitError(error: string | Error, name?: string): void {
    this.console.debug(`${this.instanceId}:emitError`);
    this._emitError(error, name);
    emitError(error, name, this);
  }
  emitEvent(name: string, data?: any): void {
    this.console.debug(`${this.instanceId}:emitEvent`);
    this._emitEvent(name, data);
    emit(name, data, this);
  }
  render(): void {
    this._renderInQueue = false;
    this.console.debug(`${this.instanceId}:render`);
    const fragment = document.createDocumentFragment();
    this._loadStyles(fragment);
    this._render(fragment);
    this.replaceChildren(fragment);
    if (this.hasAttribute('hidden'))
      this.removeAttribute('hidden')
  }
  validate(): void {
    this.console.debug(`${this.instanceId}:validate`);
    var validationErrors: string[] = [];
    if (this.hasData) {
      if (typeof this.data.locale !== 'undefined') {
        const localeString = this.data.locale.toString();
        if (localeString.trim().length !== 5 || localeString[2] !== '-')
          validationErrors.push(`The locale is not in a valid format.`);
      }

      this._validate(validationErrors);
      if (validationErrors.length > 0)
        this.emitError(new DellValidationError(`${this.localName} has invalid inputs:\n${validationErrors.join('\n')}\ndata:${JSON.stringify(this.data)}`), "validationError");
    }
  }
}
