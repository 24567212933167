import { DefaultPriorityLevel, priorityLevelSwitches, PriorityLevels, PriorityLevelSwitch } from './priotity-level-switch';
import { Switch } from '../../switch';
import { consoleMap, DellConsole } from '../console';
import { DellPerformanceMark } from './dell-performance-mark';

export class DellPerformance {
  constructor(public name: string = '', priorityLevel?: PriorityLevels) {
    this._console = consoleMap.setGet(name);
    this.priorityLevel = priorityLevelSwitches.setGet(name, priorityLevel);
  }

  protected _console: DellConsole;

  protected _formatName(name: string = '') {
    return 'dcjs-' + (this.name ? (this.name + (name ? '-' + name : '')) : name);
  }

  onresourcetimingbufferfull: ((this: globalThis.Performance, ev: Event) => any) | null = null;

  priorityLevel: Switch;

  get timeOrigin(): number {
    return window.performance.timeOrigin;
  }

  clearMarks(markName?: string): void {
    if (this.priorityLevel.hasValue())
      window.performance.clearMarks(this._formatName(markName));
  }

  clearMeasures(measureName?: string): void {
    if (this.priorityLevel.hasValue())
      window.performance.clearMeasures();
  }

  clearResourceTimings(): void {
    if (this.priorityLevel.hasValue())
      window.performance.clearResourceTimings();
  }

  getEntries(): PerformanceEntryList {
    return window.performance.getEntries();
  }

  getEntriesByName(name: string, type?: string): PerformanceEntryList {
    return window.performance.getEntriesByName(this._formatName(name), type);
  }

  getEntriesByType(type: string): PerformanceEntryList {
    return window.performance.getEntriesByName(this._formatName(), type);
  }

  mark(markName: string, markOptions?: PerformanceMarkOptions): PerformanceMark {
    if (this.priorityLevel.hasValue())
      return window.performance.mark(this._formatName(markName), markOptions);
    return {} as PerformanceMark;
  }

  logMarks(nameFilter: string | RegExp): void {
    this.logEntries(nameFilter, 'mark');
  }

  logMeasures(nameFilter: string | RegExp = ''): void {
    this.logEntries(nameFilter, 'measure');
  }

  logEntries(nameFilter: string | RegExp = '', type?: string): void {
    if (this.priorityLevel.hasValue()) {
      var performanceEntryList = type === undefined ? this.getEntries() : this.getEntriesByType(type);
      var nameFilterRegex = (typeof nameFilter == 'string') ? new RegExp(this._formatName(nameFilter), 'i') : nameFilter;
      for (var i = 0; i < performanceEntryList.length; ++i) {
        var performanceEntry = performanceEntryList[i];
        if (nameFilterRegex.test(performanceEntry.name)) {
          this._console.log('dellCoreJs.performance', JSON.stringify(performanceEntry));
        }
      }
    }
  }

  measure(measureName: string, callback: (() => void)): void;
  measure(measureName: string, startOrMeasureOptions?: string | PerformanceMeasureOptions, endMark?: string): PerformanceMeasure
  measure(p0: string, p1?: (() => void) | string | PerformanceMeasureOptions, p2?: string) {
    if (typeof p1 === 'function') {
      if (!this.priorityLevel.hasValue()) {
        p1();
        return;
      }
      const perf = this.start(p0);
      p1();
      perf.end();
      perf.log();
      return;
    }
    if (this.priorityLevel.hasValue()) {
      if (typeof p1 === 'object') {
        p1.end = this._formatName(p1.end as string),
          p1.start = this._formatName(p1.start as string)
      }
      let markStart = typeof p1 === 'string' ? this._formatName(p1) : p1;
      let markEnd = typeof p2 === 'string' ? this._formatName(p2) : undefined;
      if (typeof p1 === 'object') {
        markStart = p1.start as string;
        markEnd = p1.end as string;
      }
      return window.performance.measure(this._formatName(p0), markStart, markEnd);
    }
    return {} as PerformanceMeasure;
  }

  measureAndLog(measureName: string, startOrMeasureOptions?: string | PerformanceMeasureOptions, endMark?: string): PerformanceMeasure {
    if (this.priorityLevel.hasValue()) {
      const m = window.performance.measure(this._formatName(measureName), typeof startOrMeasureOptions === 'string' ? this._formatName(startOrMeasureOptions) : startOrMeasureOptions, this._formatName(endMark));
      this.logMeasures(measureName)
      return m;
    }
    return {} as PerformanceMeasure;
  }

  now(): number {
    return window.performance.now();
  }

  setResourceTimingBufferSize(maxSize: number): void {
    if (this.priorityLevel.hasValue())
      window.performance.setResourceTimingBufferSize(maxSize);
  }

  start(markName: string, markOptions?: PerformanceMarkOptions): DellPerformanceMark {
    const mark = new DellPerformanceMark(markName, this);
    mark.start(markOptions);
    return mark;
  }

  toJSON() {
    if (this.priorityLevel.hasValue())
      return window.performance.toJSON();
  }

  addEventListener<K extends 'resourcetimingbufferfull'>(type: K, listener: (this: globalThis.Performance, ev: PerformanceEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void;
  addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;
  addEventListener(type: any, listener: any, options?: any): void {
    if (this.priorityLevel.hasValue())
      window.performance.addEventListener(type, listener, options);
  }

  removeEventListener<K extends 'resourcetimingbufferfull'>(type: K, listener: (this: globalThis.Performance, ev: PerformanceEventMap[K]) => any, options?: boolean | EventListenerOptions): void;
  removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;
  removeEventListener(type: any, listener: any, options?: any): void {
    if (this.priorityLevel.hasValue())
      window.performance.removeEventListener(type, listener, options);
  }

  dispatchEvent(event: Event): boolean {
    if (this.priorityLevel.hasValue())
      return window.performance.dispatchEvent(event);
    return false;
  }
}
