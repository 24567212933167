export class ShadowRootArray extends Array<ShadowRoot> {
  clean() {
    for (let i = this.length - 1; i >= 0; i--) {
      if (!this[i].isConnected) {
        this.splice(i, 1);
      }
    }
  }
}

export const shadowRoots: ShadowRootArray = (typeof dellCoreJs === 'undefined' ? new ShadowRootArray() : dellCoreJs.dom.shadowRoots);
