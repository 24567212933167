import { forEachProto } from '../../core-functions';
import { consoleMap } from '../console/dell-console-map';
import { DellError } from '../dell-error';
import { DellHtmlElement, DellHtmlElementConstructor } from './dell-html-element';
import { DellHtmlElementData, DellHtmlElementDataConstructor } from './dell-html-element-data';
import { dataMapper } from './dell-html-element-data-mapper';

export function define(customElement: DellHtmlElementConstructor<DellHtmlElement, DellHtmlElementData>, customElementData?: DellHtmlElementDataConstructor): void {
  const console = consoleMap.setGet('DellCustomElements');
  console.groupCollapsed(`Define(${customElement?.elementName})`);
  try {
    console.debug("define", 'begin');
    //This 'setup' is only done once for each mfe class
    //Check to make sure mfe inherits from MfeBase
    if (customElement === undefined)
      throw new DellError('Unable to define customElement because the customElement parameter is undefined.');

    if (!(customElement.prototype instanceof DellHtmlElement) && customElement !== DellHtmlElement)
      throw new DellError(`The customElement parameter must extend the 'DellHtmlElement' class.`);

    //Check to make sure elementName is defined in mfe class
    if (typeof customElement.elementName !== 'string' || customElement.elementName.trim() === '')
      throw new DellError(`Unable to define customElement because the 'elementName' property is not defined.`);

    if (!Object.hasOwn(customElement, 'elementName'))
      throw new DellError(`The customElement (${customElement.elementName}) must have its own elementName defined locally instead of using the inherited value.`);

    //Check to make sure that the element has not already been defined on the page
    if (window.customElements.get(customElement.elementName) !== undefined)
      throw new DellError(`Unable to define customElement (${customElement.elementName}) because it has already been defined.`);

    //Verify that the customElementData param is valid and set dataClass property
    if (customElementData?.prototype instanceof DellHtmlElementData || customElementData === DellHtmlElementData)
      Object.defineProperty(customElement, 'dataClass', { configurable: false, value: customElementData });

    //Verify that the customElement.dataClass
    else if (customElement.dataClass && !(customElement.dataClass.prototype instanceof DellHtmlElementData) && customElement.dataClass !== DellHtmlElementData)
      throw new DellError(`Unable to define customElement (${customElement.elementName}) because the dataClass property does not inherit from DellHtmlElementData.`);

    //Set initialize and observedAttributes
    Object.defineProperties(customElement, {
      initialized: { configurable: false, writable: true, value: false },
      observedAttributes: { configurable: false, writable: false, value: getObservedAttributes(customElement) }
    });

    //Set options for defineElement if the custom element extends a built-in element like div, p, etc.
    let defineOptions!: ElementDefinitionOptions;
    if (customElement.extendsElement) {
      defineOptions = {
        extends: customElement.extendsElement
      }
    }

    //define the element in the DOM
    window.customElements.define(customElement.elementName, customElement, defineOptions);
    console.debug('define', 'end', 'elementName:', customElement.elementName, 'observedAttributes:', customElement.observedAttributes, 'defineOptions:', defineOptions);
  }
  catch (e) {
    console.error('define', e);
  }
  console.groupEnd();
}

function getObservedAttributes(customElement: DellHtmlElementConstructor<DellHtmlElement, DellHtmlElementData>) {
  const values: string[] = [];
  forEachProto(customElement, _customElement => {
    if (!Object.hasOwn(_customElement, '_observedAttributes')) {
      (_customElement as any)._observedAttributes = Object.hasOwn(_customElement, 'observedAttributes') ?
        dataMapper.formatNamesAttrib(_customElement.observedAttributes) : [];
    }
    values.unshift(...(_customElement as any)._observedAttributes);
  }, DellHtmlElement);
  return customElement.dataClass ? dataMapper.formatNamesAttrib(new customElement.dataClass()) : values;
}