import { json } from "../../dom";
import { guid } from "../../guid";
import { Null } from "../../types";
import { DellConsole } from "../console/dell-console";
import { consoleMap } from "../console/dell-console-map";
import { DellHtmlElement } from "./dell-html-element";

export interface DellHtmlElementDataMapper {
  attributeObserverCallback: MutationCallback;
  convertNameAttribToProp(name: string): string;
  convertNamePropToAttrib(name: string): string;
  formatNamesAttrib(keys?: {} | string[]): string[];
  mapDataAttribute(element: DellHtmlElement, name: string, oldValue: Null<string>, newValue: Null<string>): boolean;
  mapInnerTextToDataAttributes(mfe: DellHtmlElement): void;
  observeAttributes(element: DellHtmlElement): void;
  syncDataWithDataAttributes(element: DellHtmlElement): void;
}

export class DellHtmlElementDataMapper implements DellHtmlElementDataMapper {
  constructor() {
    this._console = consoleMap.setGet('DellHtmlElementDataMapper');
    this._attributeObserver = new MutationObserver((mutations, mutationObserver) => {
      //performanceMap.default.measure("DellHtmlElementDataMapper.attributeObserverCallback", () => {
      this.attributeObserverCallback(mutations, mutationObserver)
      //});
    });
  }

  private readonly _attributeObserver: MutationObserver;

  protected _console: DellConsole;

  protected _setDataProperty(element: DellHtmlElement, name: string, value: Null<string>): void {
    const dataValue = value === null ? null : json.tryParse(value).result;
    if (dataValue === null || dataValue === undefined || dataValue === '') {
      delete element.data[name]
    }
    else {
      element.data[name] = dataValue;
    }
  }

  attributeObserverCallback: MutationCallback = (mutations, mutationObserver) => {
    const mfeMutations: Record<string, { element: DellHtmlElement, attributes: Record<string, { oldValue: Null<string>; newValue: Null<string>; } | null> }> = {};
    const mutationId = guid.create();
    mutations.forEach(m => {
      const el = m.target as DellHtmlElement;
      const attributeName = m.attributeName as string;
      const oldValue = m.oldValue;
      if (attributeName.startsWith('data-') && el.observedAttributes.indexOf(attributeName) === -1) {
        const newValue = el.getAttribute(attributeName);
        if (oldValue !== newValue) {
          if (mfeMutations[el.instanceId] === undefined)
            mfeMutations[el.instanceId] = { element: el, attributes: {} };
          mfeMutations[el.instanceId].attributes[attributeName] = { oldValue: oldValue, newValue: newValue };
        }
      }
    });
    const instanceIds = Object.keys(mfeMutations);
    if (instanceIds.length === 0)
      return;
    this._console.groupCollapsed('attributeObserverCallback: Attribute Mutations', mutationId);
    this._console.debug("mfeMutations:", mfeMutations);
    instanceIds.forEach(instanceId => {
      Object.keys(mfeMutations[instanceId].attributes).forEach(attributeName => {
        const el = mfeMutations[instanceId].element;
        const oldValue = mfeMutations[instanceId].attributes[attributeName]!.oldValue;
        const newValue = mfeMutations[instanceId].attributes[attributeName]!.newValue;
        if (this.mapDataAttribute(el, attributeName, oldValue, newValue))
          this._console.debug('Update Unobserved Data Attribute:', "name:", attributeName, "oldValue:", oldValue, "newValue:", newValue);
      });
    });
    this._console.groupEnd();
  }

  convertNameAttribToProp(name: string): string {
    return name.substring(5).replace(/-([a-z])/g, (value) => value[1].toUpperCase());
  }

  convertNamePropToAttrib(name: string): string {
    return 'data-' + name.replace(/[A-Z]/g, '-$&').toLowerCase();
  }

  formatNamesAttrib(keys?: {} | string[]): string[] {
    if (keys == undefined)
      return [];
    const keyArray = Array.isArray(keys) ? keys : [...Object.keys(keys)];
    keyArray.forEach((k, i) => {
      if (keyArray[i].indexOf('data-') !== 0)
        keyArray[i] = this.convertNamePropToAttrib(k)
    });
    return keyArray;
  }

  mapDataAttribute(element: DellHtmlElement, name: string, oldValue: Null<string>, newValue: Null<string>): boolean {
    if (oldValue === newValue)
      return false;
    const dataName = this.convertNameAttribToProp(name);
    this._setDataProperty(element, dataName, newValue);
    this._console.debug('mapDataAttribute', 'dataName:', dataName, 'dataValue:', element.data[dataName]);
    return true;
  }

  mapInnerTextToDataAttributes(element: DellHtmlElement): void {
    if (element.childNodes.length === 0)//has no child nodes
      return;
    else {//ensure all direct children are text nodes
      let i = element.childNodes.length - 1;
      while (i >= 0) {
        if (element.childNodes[i--].nodeType !== Node.TEXT_NODE)
          return;
      }
      if (element.innerText.trim()==='') //ensure innerText is not empty
        return;
    }
    const parseResult = json.tryParse(element.innerText);
    if (parseResult.hasError) {
      element.emitError(`Error parsing innerText of ${element.localName}\ninnerText: ${element.innerText}\n${parseResult.error}`);
    } else {
      this._console.groupCollapsed('mapInnerTextToDataAttributes');
      Object.keys(parseResult.result).forEach((key) => {
        const value = parseResult.result[key];
        if (value !== null && value !== undefined && value !== '') {
          element.data[key] = value;
          const dataAttributeName = this.convertNamePropToAttrib(key);
          const dataAttributeValue = typeof value === 'string' ? value : JSON.stringify(value);
          this._console.debug('dataAttributeName:', dataAttributeName, 'dataAttributeValue:', dataAttributeValue);
          element.setAttribute(dataAttributeName, dataAttributeValue);
        }
      });
      this._console.groupEnd();
    }
    element.replaceChildren();
  }

  observeAttributes(element: DellHtmlElement) {
    this.mapInnerTextToDataAttributes(element);
    this._attributeObserver.observe(element, { attributes: true, attributeOldValue: true });
    this.syncDataWithDataAttributes(element);
  }

  syncDataWithDataAttributes(element: DellHtmlElement): void {
    this._console.groupCollapsed('syncDataPropertyWithDataAttribute');
    for (let i = 0; i < element.attributes.length; i++) {
      const name = element.attributes[i].name as string;
      const value = element.attributes[i].value;
      if (name.startsWith('data-')) {
        const dataName = this.convertNameAttribToProp(name);
        this._setDataProperty(element, dataName, value);
        this._console.debug('syncDataPropertyWithDataAttribute', 'attributeName:', name, 'attributeVaue:', value, 'dataName:', dataName, 'dataValue:', element.data[dataName]);
      }
    }
    this._console.groupEnd();
    element.validate();
  }
}

export const dataMapper: DellHtmlElementDataMapper = typeof dellCoreJs === 'undefined' ? new DellHtmlElementDataMapper() : dellCoreJs?.dom.customElements.dataMapper;
