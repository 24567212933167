import { DellMapString } from '../dell-map';
import { NullUndefString, Undef } from '../types';
import { Request } from './request';
import { GetParams, Methods, PostParams, RequestHandler } from './types';

export * from './request';
export * from './types';

export function get(params: GetParams): void {
  send(Methods.GET, params.url, undefined, params.headers, params.success, params.error, params.complete);
}

export function post(params: PostParams): void {
  send(Methods.POST, params.url, params.body, params.headers, params.success, params.error, params.complete);
}

export function postJson(params: PostParams): void {
  if (!params.headers)
    params.headers = new DellMapString();
  params.headers.set('Content-Type', 'application/json;charset=UTF-8');
  send(Methods.POST, params.url, params.body, params.headers, params.success, params.error, params.complete);
}

export function send(
  method: Methods,
  url: string,
  body: NullUndefString,
  headers: Undef<DellMapString<string>> = undefined,
  success: RequestHandler,
  error: RequestHandler,
  complete: RequestHandler
): void {
  new Request(method, url)
    .setBody(body)
    .setRequestHeaders(headers)
    .onSuccess(success)
    .onError(error)
    .onComplete(complete)
    .send();
}

